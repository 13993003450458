import React, {useEffect, useState} from 'react';
import Footer from "../components/footer/Footer";
import MenuLeft from "../components/menu-left";
import FormUploadBook from "../components/formUploadBook";
import Button from "../components/button/Boton";
import MyDropzone from "../components/drop-zone/MyDropZone";
import Global from "./Global";
import toastr from 'toastr';
import CustomImageLoader from '../components/CustomLoader/CustomImageLoader';
import EndpointCore from '../pages/Global';
import ContenidoOriginalModal from '../components/contenido-original-modal/ContenidoOriginalModal';
import useAuth from "../hook/useAuth";

const readFileAsBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(',')[1]);
    reader.onerror = (error) => reject(error);
  });

export default function UploadBook() {
  const [next, setNext] = useState(false);
  const [error, setError] = useState(null);
  const [categoria, setCategoria] = useState([]);
  const [etiquetas, setEtiquetas] = useState([]);
  const [demografia, setDemografia] = useState([]);
  const [statusFilter, setStatusFilter] = useState([]);
  const [typesFilter, setTypesFilter] = useState([]);
  const [agrupacion, setAgrupacion] = useState([]);
  const [languageOptions, setLanguageOptions] = useState([]);
  const [images, setImages] = useState({ image1: null, image2: null });
  const [profileImageSrc, setProfileImageSrc] = useState("img/svg/defaultImage.svg");
  const [coverImageSrc, setCoverImageSrc] = useState("img/svg/defaultImage.svg");
  const [bookContentType, setBookContentType] = useState({
    tipo: 'PUBLIC', // Puede ser 'PUBLIC' o 'CONTENIDO ORIGINAL'
    id: null,      // ID de la opción seleccionada (null para PUBLIC)
    titulo: null,  // Titulo de la opción seleccionada (null para PUBLIC)
  });
  const [add1, setAdded1] = useState(false);
  console.log('add1', add1)
  const [add2, setAdded2]  = useState(false);
  const [formState, setFormState] = useState({
    nombreManga: "",
    idioma: "",
    autor: "",
    agrupacion: "",
    nombreAlternativo: "",
    nombreAlternativo2: "",
    estado: "",
    demografia: "",
    categoria: "",
    etiquetas: "",
    bookContentType: "",
    descripcion: "",
    imagenPerfil: "",
    imagenPerfilName: "",
    imagenPortada: "",
    imagenPortadaName: "",
    fecha: "",
    bookTypeId: "",
    bookContentType: bookContentType,
  });
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const auth = useAuth();
  const userAuthor = auth?.auth?.user_name;    

 
  const handleImageChange = (event, imageNumber) => {
    const file = event.target.files[0];
  
    // Actualizar el estado con la imagen seleccionada
    setImages((prevImages) => ({
      ...prevImages,
      [imageNumber]: file,
    }));
  
    // Mostrar la imagen seleccionada en la interfaz de usuario
    if (imageNumber === 'image1') {
      setProfileImageSrc(URL.createObjectURL(file));
      setAdded1(true);
    } else if (imageNumber === 'image2') {
      setCoverImageSrc(URL.createObjectURL(file));
      setAdded2(true);
    }
  };
  
  const fetchAttributes = async () => {
    try {
      const response = await fetch('https://dmangas-core.jmasoluciones.pw/public/api/core/attributes?limitPage=72&orderByColumn=lastestUpdated&orderDir=DESC&attributeType=null');
      const data = await response.json();
      return data;     
    } catch (error) {
      throw new Error('Error fetching attributes: ' + error.message);
    }
  };

  const fetchTypes = async () => {
    try {
      const response = await fetch('https://dmangas-core.jmasoluciones.pw/api/core/book-types?limitPage=100');
      const data = await response.json();

      return data;
    } catch (error) {
      throw new Error('Error fetching types: ' + error.message);
    }
  };

  const fetchStatus = async () => {
    try {
      const response = await fetch('https://dmangas-core.jmasoluciones.pw/api/core/book-activity-status?limitPage=100');
      const data = await response.json();
      return data;
    } catch (error) {
      throw new Error('Error fetching status: ' + error.message);
    }
  };

  const loadDataFromLocalStorage = async () => {
    let attributeData = JSON.parse(localStorage.getItem('attributesData'));
    let typesData = JSON.parse(localStorage.getItem('bookTypeData'));
    let statusData = JSON.parse(localStorage.getItem('bookActivityStatusData'));

    if (!attributeData) {
      attributeData = await fetchAttributes();
      localStorage.setItem('attributesData', JSON.stringify(attributeData));
    }

    if (!typesData) {
      typesData = await fetchTypes();
      localStorage.setItem('bookTypeData', JSON.stringify(typesData));
    }

    if (!statusData) {
      statusData = await fetchStatus();
      localStorage.setItem('bookActivityStatusData', JSON.stringify(statusData));
    }

   
    return { attributeData, typesData, statusData};
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { attributeData, typesData, statusData } = await loadDataFromLocalStorage();
        const statusFilterData = statusData?.items.map((item) => ({ item: item.name.value })) || [];
        const typesFilterData = typesData?.items.map((item, index) => ({  item: item.alias, index : index + 1 })) || [];
        const etiquetasFilterData = attributeData?.items.filter(item => item.type.name === "Etiquetas") || [];
        const etiquetasData = etiquetasFilterData.map((item) => ({ 
          value: item.attributeAlias, // Utilizar attributeAlias como valor del dropdown
          label: item.attributeName.value // Mostrar attributeName.value en el dropdown
        }));        
        const categoriaFilterData = attributeData?.items.filter(item => item.type.name === "Categorías") || [];
        const categoriaData = categoriaFilterData.map((item) => ({ 
        value: item.attributeAlias, // Utilizar attributeAlias como valor del dropdown
        label: item.attributeName.value // Mostrar attributeName.value en el dropdown
        }));        
        const demografiaFilterData = attributeData?.items.filter(item => item.type.name === "Demografía") || [];
        const demografiaData = demografiaFilterData.map((item) => ({ item: item.attributeAlias}));
        const languageOptionsData = [...new Set(attributeData?.items.map(item => item.attributeName.languageCode))]
        .map(languageCode => ({ item: languageCode }));
        setStatusFilter(statusFilterData);
        setCategoria(categoriaData);
        setEtiquetas(etiquetasData);
        setDemografia(demografiaData);
        setLanguageOptions(languageOptionsData);
        setTypesFilter(typesFilterData);

      } catch (error) {
        setError(error.message);
      }
    };

    fetchData();

  }, []);

  useEffect(() => {
    const fetchAgrupacion = async () => {
      try {
        const jwt = localStorage.getItem('token');
  
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwt}`,
        };
  
        const response = await fetch(EndpointCore.getGroupsByMemberCode, { headers });
        const agrupacionData = await response.json();
        // Filtrar agrupaciones por el autor del usuario
        const agrupacionFilterData = agrupacionData.groups
          .filter((item) => item.author === userAuthor)
          .map((item) => ({ id: item.id, name: item.name, author: item.author }));
  
        setAgrupacion(agrupacionFilterData);
      } catch (error) {
        console.error('Error fetching groups:', error.message);
      }
    };
  
    fetchAgrupacion();
  }, [userAuthor]); // Agregar `userAuthor` como dependencia
  
  const handleUploadBook = async () => {
    try {
      setLoading(true)
      if (!images.image1 || !images.image2) {
      toastr.error('Debe seleccionar ambas imágenes');
      return;
    }
      if (!formState.nombreManga || !formState.idioma || !formState.autor || !formState.agrupacion || !formState.estado || !formState.demografia || !formState.categoria || !formState.etiquetas || !formState.bookContentType || !formState.descripcion ) {
        toastr.error('Todos los campos son obligatorios');
        return;
      }

      const imagenPerfilBase64 = await readFileAsBase64(images.image1);
      const imagenPortadaBase64 = await readFileAsBase64(images.image2);
  
      const newFormState = {
        ...formState,
        imagenPerfil: imagenPerfilBase64,
        imagenPortada: imagenPortadaBase64,
        imagenPerfilName: images.image1.name,
        imagenPortadaName: images.image2.name,
      };
  
      setFormState(newFormState);

      const response = await fetch(Global.createBook, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newFormState),
      });
      if (response.ok) {
        const data = await response.json();
        toastr.success('Libro creado exitosamente');
        console.log('Libro creado con éxito:', data);
      } else {
        const errorData = await response.json();
        toastr.error(errorData.message);
        console.error('Error al crear el libro:', errorData.message);
      }
    } catch (error) {
      toastr.error('Error al procesar la solicitud');
      console.error('Error al procesar la solicitud:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxChange = (value) => {
    const backendValueMap = {
      "Dominio Público (traducción)": { id: null, tipo: "PUBLIC", titulo: null },
      "Contenido Original (Con derechos de Autor)": { id: null, tipo: "ORIGINAL_CONTENT", titulo: "Con derechos de Autor" },
    };
  
    const selectedValue = backendValueMap[value] || null;
  
    // Setea bookContentType al objeto correspondiente
    setFormState((prevFormState) => ({
      ...prevFormState,
      bookContentType: selectedValue,
    }));
  };



  const openModal = () => {
    setShowModal(true);
  };

  const handleContinueButtonClick = () => {
    if (!formState.nombreManga || !formState.idioma || !formState.autor || !formState.agrupacion || !formState.estado || !formState.demografia || !formState.categoria || !formState.etiquetas || !formState.bookContentType || !formState.descripcion ) {
      toastr.error('Todos los campos son obligatorios');
    } else {
      setNext(true);
    }
  };

  const handleBookContentTypeChange = (selectedOption) => {
    if (selectedOption) {
      const newBookContentType = {
        tipo: 'ORIGINAL_CONTENT',
        id: selectedOption.id,
        titulo: selectedOption.titulo,
      };
      setBookContentType(newBookContentType);
      setFormState((prevFormState) => ({
        ...prevFormState,
        bookContentType: newBookContentType,
      }));
    } else {
      setBookContentType({
        tipo: 'PUBLIC',
        id: null,
        titulo: null,
      });
      setFormState((prevFormState) => ({
        ...prevFormState,
        bookContentType: {
          tipo: 'PUBLIC',
          id: null,
          titulo: null,
        },
      }));
    }
  };

  console.log(formState)
  return (
    <div className="interna comunidad_solicitudes">
      <div className="interna libro comunidad">
        <div className="position_header position ">
          <div className="seccion_interna">
            <div className="ui_container ui_container--946 book-upload book-edit">
            <div style={{ position: 'fixed',  top: '20px', bottom: '20px' , height: `calc(100vh - 40px)`,}}>
               <MenuLeft />
            </div>              
              <div className="col01 w-71 contenido" style={{marginLeft: '310px', marginBottom: '20px', marginTop: '20px', minHeight: '1200px'}}>
                <div className="box header" style={{ height: 32, marginTop: '15px'}}>
                  <span className='uploadbook'>Crear Libro</span>
                </div>
                {!next ? (
                  <div className="box body d-flex flex-column">
                    <div className="box grid-tab">
                      <FormUploadBook 
                        categoria={categoria} 
                        etiquetas={etiquetas} 
                        demografia={demografia} 
                        statusFilter={statusFilter} 
                        languageOptions={languageOptions}
                        formState={formState}
                        setFormState={setFormState}
                        typesFilter={typesFilter}
                        agrupacion ={agrupacion}
                      />
                    </div>
                     <div className={'form-check'}>
                       <input
                       className="form-check-input"
                       type="checkbox"
                       value="Dominio Público (traducción)"
                       checked={formState.bookContentType.tipo === "PUBLIC"}
                       onChange={() => handleCheckboxChange("Dominio Público (traducción)")}
                       />
                      <span className='type-content'>Dominio Público (traducción)</span>
                     </div>
                     <div className={'form-check'}>
                       <input
                       className="form-check-input"
                       type="checkbox"
                       value="Contenido Original (Con derechos de Autor)"
                       checked={ formState.bookContentType.tipo === "ORIGINAL_CONTENT"}   
                       onChange={() => handleCheckboxChange("Contenido Original (Con derechos de Autor)")}
                       onClick={openModal}
                      />
                     <span className='type-content'>Contenido Original ({bookContentType?.titulo || "Con derechos de Autor"})</span>
                     </div>
                    <li className="ibillete px-3 max-width-full-important">
                     <div
                      className="group-date historia "
                      style={{
                        background: "#FFFFFF",
                        border: "1px solid #5F36DA",
                        boxShadow: "0px 1px 1px rgba(51, 154, 240, 0.1)",
                        borderRadius: "8px",
                        maxWidth: "100%",
                        padding: "5px 20px",
                        display: "inline-block",
                        marginRight: "30px",
                        width: "100%",
                        marginBlock: 0,
                      }}
                    >
                      <span style={{ fontFamily: "var(--font-Poppins-Medium)", fontSize: "12px" }}>Descripción</span>
                      <textarea
                        className="w-100"
                        rows={3}
                        style={{
                          height: "108px",
                          maxHeight: "500px",
                          resize: "none",
                          overflow: "auto",  
                          fontFamily: "var(--font-Poppins-Medium)",
                        }}
                        onChange={(e) => {
                          const textarea = e.target;
                          textarea.style.height = "108px";  // Restablece a la altura inicial antes de calcular
                          textarea.style.height = Math.min(textarea.scrollHeight, 500) + "px";  // Ajusta hasta 500px
                          setFormState({
                            ...formState,
                            descripcion: textarea.value,
                          });
                        }}
                      ></textarea>
                    </div>
                    </li>
                    <div className={'form-check'}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        defaultValue
                      />
                      <span className='term-condition'>He leído y acepto los términos y condiciones</span>
                    </div>
                    <div className="w-full d-flex justify-content-center my-5">
                      <Button title="continuar" onClick={handleContinueButtonClick}  />
                    </div>
                  </div>
                ) : (
                  <ul className="catalogo" style={{marginTop: '-10px'}}>
                  <li className="ibillete">
                    <span className='uploadbook-span' style={{marginBottom: '5px'}}>APARIENCIA</span>
                    <span style={{color: '#424242'}}>Dar una buena imagen es importante:</span>
                    <div className="group-date fila no-borde">
                      <div className="apariencia">
                        <div className="group-date fila no-borde">
                          <div className={`r_item uploadbook ${add1 ? 'uploadbook-added' : ''}`}>
                           <MyDropzone
                             handleImageChange={handleImageChange}
                             imageNumber="image1"
                             imageSrc={profileImageSrc}
                            />                                         
                            <div className='inputs-perfil uploadbook'>
                              <strong className="imagen-titulo">Imagen de perfil</strong>
                              <span className="imagen-min" style={{marginBottom: '5px', marginLeft: '0px', display: 'block', textAlign: 'left'}}>(Recomendado 800X80px)</span>
                              <label className="btn-file-upload btn1 uploadbook" style={{fontSize: '14px'}}>
                                Seleccionar imagen
                                 <input
                                  type="file"
                                  accept="image/*"
                                  style={{ display: "none" }}
                                  onChange={(event) => handleImageChange(event, 'image1')}
                                 />
                              </label>                             
                           </div>
                        </div>
                          <div className="portada-section" style={{marginTop: '40px'}}>
                            <strong className='titulo-portada'>Imagen de perfil segunda vista</strong>
                            <span className="man" style={{marginLeft: '0px', marginBottom: '10px'}}>(Recomendado 800X80px)</span>
                            <div>
                              <MyDropzone
                                handleImageChange={handleImageChange}
                                imageNumber="image2"
                                imageSrc={coverImageSrc}
                                add2={add2}
                              />
                              <label className="btn-file-upload btn2 " 
                              style={{width: '196px',  marginTop: '20px', fontSize: '14px',  whiteSpace: 'nowrap' }} >
                              Seleccionar imagen
                                <input
                                  type="file"
                                  accept="image/*"
                                  style={{ display: "none" }}
                                  onChange={(event) => handleImageChange(event, 'image2')}
                                />
                              </label>
                              {loading && <CustomImageLoader/>}
                            </div>
                            <div className="mt-5">
                              <Button title={"Subir libro"} type="button"  onClick={handleUploadBook}/>
                            </div>
                          </div>
                        </div>
                      </div>
                      </div>
                    </li>
                  </ul>    
                  )}
                  <div className="hide_movil footer-menu">
                   <Footer  />
                  </div>                   
                 </div>
                </div>
            </div>
          </div>
        </div>
        <ContenidoOriginalModal show={showModal} setShow={setShowModal} onSeleccionarOpcion={handleBookContentTypeChange}  />
      </div>
  );
}